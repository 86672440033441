@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.team {
    width: 100%;
    height: 80vh;
    position: relative;
    background: rgba(0, 0, 0, 0.349);
  }
  
  .team:before {
    content: '';
    position: absolute;
    background: url('../../assets/submenu-bg-team.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.team .team-content {
    width: 40%;
    display: block;
    position: absolute;
    bottom: 20%;
    right: 10%;
    padding: 1rem;
}


.team h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.team .team-content {
        width: 100%;
        display: block;
        position: absolute;
        top: 60%;
        padding: 1rem;
    }

.team h1 {
    font-size: 3rem;
    text-align: center;
}
}

@media screen and (max-width:700px) {
    .team {
        height: 100vh;
      }

      .team .team-content {
        bottom: 30%;
        right: 0%;
    }
}