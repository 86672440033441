


.mission-section {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 3rem 1rem;
}

.flex-left {
    width: 50%;
    margin: auto 0;
}


.mission-section-header {
    padding: 1rem 0;
}

.mission-section-header h1 {
    padding: .5rem 0;
}

.flex-left h1 {
    padding: .2rem 0;
    font-size: 1rem;
    text-align: left;
}

.flex-left p {
    text-align: left;
}

.flex-right {
    width: 500px;
}

.flex-right img {
    width: 100%;
}

@media screen and (max-width:940px)  {
    .mission-section {
        flex-wrap: wrap;
    }

    .flex-left {
        width: 100%;
    }
}