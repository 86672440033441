@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.Schizoaffective {
    width: 100%;
    height: 70vh;

    background: #0000007a;
    position: relative;
  }
  
  .Schizoaffective:before {
    content: '';
    position: absolute;
    background: url('../../assets/mental-health-submenu-bg.webp') no-repeat center center/cover;
    height: 70vh;

    width: 100%;
    z-index: -1;
  }
  

.Schizoaffective {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Schizoaffective .Schizoaffective-content {
    display: block;
    position: absolute;
   bottom: 20%;
    padding: 1rem;
}

.Schizoaffective h1 {
    font-size: 3rem;    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.678);
}

.Schizoaffective h3 {
    color: rgba(255, 255, 255, 0.664);
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.Schizoaffective h1 {
    font-size: 2rem;
}

.Schizoaffective h3 {
    font-size: 1rem;
}

}