@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact-hero {
    width: 100%;
    height: 80vh;
    position: relative;
    background: #12121379;
  }
  
  .contact-hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-contact.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.contact-hero .contact-hero-content {
    position: absolute;
    width: 40%;
    bottom: 20%;
    left: 10%;
    padding: 1rem;
}

.contact-hero h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(223, 218, 218);
    text-align: left;
}

.contact-hero h2 {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(243, 236, 236);
    padding: 1rem 0;
    text-align: left;
    letter-spacing: 3px;
}

.contact-hero p {
    font-family: 'Montserrat', sans-serif;
    color: rgb(233, 233, 233);
}

.contact-hero span {
    color: white;
}

/* MEDIA QUERIES */



@media screen and (max-width:940px) {

.contact-hero .contact-hero-content {
    position: absolute;
    width: 100%;
    top: 60%;
    left: 0%;
    padding: 1rem;
}

.contact-hero h1 {
    text-align: center;
    font-size: 2rem;
}

.contact-hero h2 {
    text-align: center;
    font-size: 2rem;
}

.contact-hero p {
    text-align: center;
}

}


@media screen and (max-width:700px) {

    .contact-hero {
        height: 100vh;
      }

    .contact-hero p {
        font-size: 14px;
    }

    }