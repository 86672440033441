@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.Schizophrenia {
    width: 100%;
    height: 70vh;

    background: #0000007a;
    position: relative;
  }
  
  .Schizophrenia:before {
    content: '';
    position: absolute;
    background: url('../../assets/mental-health-submenu-bg.webp') no-repeat center center/cover;
    height: 70vh;

    width: 100%;
    z-index: -1;
  }
  

.Schizophrenia {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Schizophrenia .Schizophrenia-content {
    display: block;
    position: absolute;
   bottom: 20%;
    padding: 1rem;
}

.Schizophrenia h1 {
    font-size: 3rem;    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.76);
}

.Schizophrenia h3 {
    color: rgba(255, 255, 255, 0.753);
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.Schizophrenia h1 {
    font-size: 2rem;
}

.Schizophrenia h3 {
    font-size: 1rem;
}

}