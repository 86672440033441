@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.bg {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .bg:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg1.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.05;
  }


.wrapper-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1rem;
}

.wrapper-content-left {
    width: 450px;
    text-align: center;
    margin: auto;
}

.wrapper-content-left img  {
    width: 100%;
}

.wrapper-content-right {
    width: 45%;
    margin: auto;
}


.wrapper-content-right h2 {
    font-size: 1rem;
    color: #000000d3;
}

.wrapper-content-right h1 {
    color: var(--primary-color);
    font-size: 1.5rem;
    letter-spacing: 2px; 
    font-family: "Elsie", serif;
    font-weight: 400;
    font-style: normal;
}

.wrapper-content-right p {
    text-align: justify;
}


.leaf {
    position: absolute;
    width: 40%;
    left: 1%;
    bottom: 0%;
}



@media screen and (max-width:940px)  {

    .wrapper-content {
        flex-wrap: wrap;
    }

    .wrapper-content-left {
        width: 80%;
    }

    .wrapper-content-right {
        width: 100%;
        padding: 1rem 2rem;
    }

    .wrapper-content-right h1, .wrapper-content-right h2{
        text-align: center;
    }




}

