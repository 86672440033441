@import url('https://fonts.googleapis.com/css2?family=Playfair&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');



.hero {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hero-content {
  position: relative;
}

.hero .hero-container {
  position: absolute;
  text-align: center;
  width: 100%;
  background-color:#0000007e;
  height: 100%;
}

.hero-container .content {
  position: absolute;
  bottom: 5%;
  padding: 1rem;
  text-align: center;
  width: 100%;
}

.hero-container .content img {
  width: 500px;
}

.hero-number {
  position: absolute;
  display: flex;
  gap: .3rem;
  top:4%;
  right: 5%;
  z-index: 10;
}

.hero-number a {
  font-size: 1.3rem;
  color: #ffffff;
  font-family: 'Libre Baskerville', serif;
  }

  .hero-number span {
    color: white;
  }

.hero .content h1 {
  font-size: 1.2rem;
  text-align: center;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;  
  padding: .5rem 0;
  letter-spacing: 2px;
}

.hero .content p {
  text-align: center;
  color: #ffffff;
  font-size: 0.8rem;
}


.hero .content h2 span {
  color: #fa9006;
  font-size: 1.2rem;
}

.hero-btn a {
font-size: 1.8rem;
color: #ffffff;
font-family: 'Libre Baskerville', serif;
}

.hero .hero-btn button {
  cursor: pointer;
  color: #ffffff;
  border: none;
  padding: .5rem 2rem;
  border-radius: 5px;
  font-size: 1rem;
}

.gauges {
justify-content: center;
gap: 1rem;
text-align: center;
font-size: 1rem;
}

.gauge-container {
width: 100%;
text-align: center;
}


.fling-minislide { 
  display:block; 
  width:100%; 
  height:100vh; 
  overflow:hidden; 
  position:relative; 
  background-color:#000000;
}

.fling-minislide img { 
  position:absolute; 
  animation:fling-minislide 32s infinite, zoom-in 32s infinite; 
  opacity:0;  
  object-fit:cover; 
  width:100vw; 
  height:100%;
} 
  
  /**/

@keyframes fling-minislide {28%{opacity:1;} 32%{opacity:0;}}
.fling-minislide img:nth-child(4){animation-delay:0s;}
.fling-minislide img:nth-child(3){animation-delay:8s;}
.fling-minislide img:nth-child(2){animation-delay:16s;}
.fling-minislide img:nth-child(1){animation-delay:24s;}

@keyframes zoom-in{0%{transform:scale(1) translateY(0);transform-origin:50% 84%}100%{transform:scale(1.5) translateY(15px);transform-origin:center}}

.title-wrapper {
  background-color: rgba(0, 0, 0, 0.404);
}


/* MEDIA QUERIES */



@media screen and (max-width:940px) {


  .fling-minislide { 
    height:100vh; 
  }

}


@media screen and (max-width:700px) {


  .hero-btn button {
    padding: .5rem 2rem;
    margin: 1rem 0;
    font-size: .8rem;
  }


  
  .hero .content h2 {
    font-size: .8rem;
    padding: .5rem 0;
  }
  
}


@media screen and (max-width: 600px) {
  .hero-container .content img {
    width: 90%;
  }

  .hero-number {
    position: absolute;
    top:4%;
    right: 5%;
    z-index: 10;
  }
  
  .hero-number a {
    font-size: 1.3rem;
    }

}
