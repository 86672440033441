@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.jobs-container {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .jobs-container:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg1.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }


.wrapper-content {
    display: flex;
    justify-content: center;
    padding: 5rem 0;
}

.jobs-left {
    width: 50%;
    text-align: center;
    margin: auto;
}

.jobs-left img {
    width: 80%;
    border-radius: 10px;
}

.jobs-right {
    width: 600px;
    margin: auto;
    padding: 0 2rem;
}


.jobs-right h2 {
    font-size: 1.5rem;
    color: #0f0f0f;
}

.jobs-right h1 {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: 1rem;
    letter-spacing: 8px; 
}

.jobs-right p {
    line-height: 25px;
    text-align: justify;
}



@media screen and (max-width:940px)  {
    .jobs-right {
        width: 100%;
    }

    .jobs-right h1, .jobs-right h2, .jobs-right p {
        text-align: justify;
    }

    .jobs-left {
        width: 100%;
    }

}

