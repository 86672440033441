@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.cocaine {
    width: 100%;
    height: 70vh;
    background: #0000007a;
    position: relative;
  }
  
  .cocaine:before {
    content: '';
    position: absolute;
    background: url('../../assets/addiction-cocaine.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.cocaine {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cocaine .cocaine-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.cocaine h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.cocaine h1 {
    font-size: 3rem;
    text-align: center;
}

}