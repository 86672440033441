@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

.homeSection2 {
    padding: 5rem 1rem;
}

.homeSection2-content {
    display: flex;
    gap: 1rem;
    text-align: left;
}

.homeSection2-left {
    width: 45%;
}

.homeSection2-right {
    width: 600px;
    position: relative;
    margin: auto;
}


.homeSection2-right img {
    width: 100%;
    border-radius: 1rem;
}

.homeSection2-content h1 {
    font-size: 1rem;
    padding: 1rem 0;
    letter-spacing: 5px;
    color: var(--primary-color);
}

.homeSection2-content h2 {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.76);
    font-family: 'DM Serif Display', serif;
}


.homeSection2-content p {
    font-size: 14px;
    text-align: left;
    color: rgb(0, 0, 0);
    padding: .5rem 0;
}

.checklist {
    display: flex;
    gap: 1rem;
}

.homeSection2-icon {
    font-size: 1.2rem;
    color: var(--primary-color);
    margin: auto 0;
}


.leaf2 {
    position: absolute;
    width: 25%;
    right: -3%;
    bottom: -5%;
}



@media screen and (max-width:940px) {
    .homeSection2-content {
        flex-wrap: wrap
    }

    .homeSection2-left {
        width: 100%;
    }

    .homeSection2 {
        padding: 1rem 1rem;
        margin: 1rem 0 7rem  0;
    }

    .homeSection2-right {
        width: 100%;
    } 

    .leaf2 {
        display: none;
    }

}

@media screen and (max-width:700px) {
    .homeSection2-left h1 {
        text-align: center;
    }

    .homeSection2-left h2 {
        text-align: center;
        font-size: 1.5rem;
    }
}