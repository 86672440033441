@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.main {
    text-align: center;
}

.view {
    width: 300px;
    height: 200px;
    margin: 10px;
    float: left;
    border: 10px solid #fff;
    overflow: hidden;
    position: relative;
    text-align: center;
    -webkit-box-shadow: 1px 1px 2px #e6e6e6;
    -moz-box-shadow: 1px 1px 2px #e6e6e6;
    box-shadow: 1px 1px 2px #e6e6e6;
    cursor: default;
    background: #fff;
 }

 .view .mask,.view .content {
    width: 300px;
    height: 300px;
    position: absolute;
    overflow: hidden;
    top: 50%;
 }
 .view img {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;

 }
 .view p {
    text-transform: uppercase;
    color: #111111;
    text-align: center;
    font-size: 17px;
    margin: auto;
 }

 .view h2 {
    font-family: Georgia, serif;
    font-style: italic;
    font-size: 12px;
    position: relative;
    padding: 10px 20px 20px;
    text-align: center;
 }

 .main .title h2 {
    color: var(--primary-color);
    font-size: 1rem;
 }


 .view-fourth img {
    -webkit-transition: all 0.4s ease-in-out 0.2s;
    -moz-transition: all 0.4s ease-in-out 0.2s;
    -o-transition: all 0.4s ease-in-out 0.2s;
    -ms-transition: all 0.4s ease-in-out 0.2s;
    transition: all 0.4s ease-in-out 0.2s;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
 }
 .view-fourth .mask {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0) rotate(-180deg);
    -moz-transform: scale(0) rotate(-180deg);
    -o-transform: scale(0) rotate(-180deg);
    -ms-transform: scale(0) rotate(-180deg);
    transform: scale(0) rotate(-180deg);
    -webkit-transition: all 0.4s ease-in;
    -moz-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    -ms-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
 }
 
 
 
 .view-fourth:hover .mask {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: scale(1) rotate(0deg);
    -moz-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    transition-delay: 0.2s;
 } 
 .view-fourth:hover img {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    -ms-transition-delay: 0s;
    transition-delay: 0s;
 }
 .view-fourth:hover h2,
 .view-fourth:hover p,
 .view-fourth:hover a.info {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    -ms-transition-delay: 0.5s;
    transition-delay: 0.5s;
 }
 /* End Hover effects */ 
 
 
 /*This sets the style of the "More info" box/button insied the image back/fliped page */
 
 .view a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    -webkit-box-shadow: 0 0 1px #000;
    -moz-box-shadow: 0 0 1px #000;
    box-shadow: 0 0 1px #000;
 }
 .view a.info:hover {
    -webkit-box-shadow: 0 0 5px #000;
    -moz-box-shadow: 0 0 5px #000;
    box-shadow: 0 0 5px #000;
 }
 
 /*End style of the "More info" box/button insied the image back/fliped page */
 



.top-flex {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  background-color: rgb(255, 255, 255);
  margin: 3rem 5rem;
}

@media (max-width: 940px) {
  .top-flex {
    margin: 0 2rem;
  }
}


